import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { ResultQueryTable, CodeError } from '@/components/pages/question';
import { RunQueryResult } from '@/types/models';
import Loader from '@/components/common/Loader';

interface RunResultProps {
  onChevronClick: () => void;
  result: RunQueryResult | null;
  error: string | null;
  isLoading: boolean;
}

const RunResult: FC<RunResultProps> = ({ onChevronClick, result, error, isLoading }) => {
  return (
    <Flex direction="column" shrink={0}>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <CodeError errorMessage={error} />
      ) : (
        result && <ResultQueryTable table={result.output} shouldHideTable hideTable={onChevronClick} />
      )}
    </Flex>
  );
};

export default RunResult;
