/**
 * Identify the entry point function in pythonQuery.
 * - Ensures the latest defined function is prioritized as the entry point (it's sensitive to the function defenitions order).
 * - Detects if a function calls itself by checking functionsCalled.
 */
export const getTopLevelFunctionName = (pythonQuery: string): string | null => {
  // Regular expressions for function definitions and calls
  const definitionRegex = /^def\s+([a-zA-Z_]\w*)\s*\(/gm;
  const callRegex = /\b([a-zA-Z_]\w*)\s*\(/g;

  const functionsDefined: string[] = [];
  const functionsCalled: Set<string> = new Set();

  let match;

  // Collect all function definitions in the order they appear
  while ((match = definitionRegex.exec(pythonQuery)) !== null) {
    const functionName = match[1];
    functionsDefined.push(functionName);
  }

  // Collect all function calls
  while ((match = callRegex.exec(pythonQuery)) !== null) {
    const calledFunction = match[1];
    functionsCalled.add(calledFunction);
  }

  // Check functions in reverse order to find the entry point
  for (let i = functionsDefined.length - 1; i >= 0; i--) {
    const functionName = functionsDefined[i];

    // Case 3: Recursion
    if (functionsCalled.has(functionName)) {
      return functionName;
    }

    // Case 2: The latest function definition
    if (i === functionsDefined.length - 1) {
      return functionName;
    }
  }

  // Case 1: Single function definition not called
  return functionsDefined.length > 0 ? functionsDefined[0] : null;
};
