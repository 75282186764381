import { FC } from 'react';
import { Code, Flex, FlexProps, Text } from '@chakra-ui/react';

interface CodeErrorProps extends Omit<FlexProps, 'title'> {
  title?: string;
  errorMessage: string;
}

const CodeError: FC<CodeErrorProps> = ({ title = 'Error', errorMessage }) => (
  <Flex direction="column" color="red.850" p="6px 8px" border="1px solid" borderColor="red.850" borderRadius="6px">
    <Text fontSize="16px" fontWeight={600} lineHeight="24px">
      {title}
    </Text>

    <Code color="red.850" fontSize="16px" lineHeight="24px" paddingInlineStart={0}>
      {errorMessage}
    </Code>
  </Flex>
);

export default CodeError;
