import { forwardRef } from 'react';
import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

/** That component is a preferable way for providing links across the project.
 *  It combines functionality of Next's Link component with Chakra's approach of stylization
 */

interface AppLinkProps extends ChakraLinkProps, Omit<NextLinkProps, 'as' | 'href'> {}

const AppLink = forwardRef<HTMLElement, AppLinkProps>(
  ({ href, replace, scroll, prefetch, children, ...chakraStyleProps }, ref) => (
    <ChakraLink
      ref={ref}
      as={NextLink}
      {...chakraStyleProps}
      href={href}
      replace={replace}
      scroll={scroll}
      prefetch={prefetch}
    >
      {children}
    </ChakraLink>
  ),
);

AppLink.displayName = 'AppLink';

export default AppLink;
