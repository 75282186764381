import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import cs from 'classnames';
import { Flex, Wrap } from '@chakra-ui/react';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { Loader } from '@/components/common';
import styles from './MarkdownEditorCustom.module.scss';
import '@uiw/react-md-editor/markdown-editor.css';
import '@uiw/react-markdown-preview/markdown.css';
import 'katex/dist/katex.min.css';

const MarkdownEditor = dynamic(() => import('@uiw/react-md-editor'), {
  ssr: false,
  loading: () => (
    <Flex w="100%" justifyContent="center">
      <Loader height="200px" />
    </Flex>
  ),
});

interface MarkdownEditorCustomProps {
  value: string;
  onChange: (val?: string) => void;
  className?: string;
}

const MarkdownEditorCustom: FC<MarkdownEditorCustomProps> = ({ className = '', value, onChange }) => {
  return (
    <Wrap w="100%" data-color-mode="light">
      <MarkdownEditor
        autoFocus
        className={cs(className, styles.editor)}
        value={value}
        onChange={onChange}
        previewOptions={{
          remarkPlugins: [remarkMath],
          rehypePlugins: [rehypeKatex],
        }}
      />
    </Wrap>
  );
};

export default MarkdownEditorCustom;
