import { SupportedRuntime } from '@/constants';
import {
  CommonProcessedRuntimeResponse,
  ErrorMessageProcessorOptions,
  ForbiddenTablesSelectingQuery,
  MutatingQuery,
  RUNTIME_TYPE_TO_RUNTIME_CLIENT,
  RUNTIME_TYPE_TO_RUNTIME_RESPONSE,
  SelectingQuery,
} from '@/types';

// TODO: check possibility to convert to  interface
export abstract class IRuntime<T extends SupportedRuntime> {
  protected runtime!: RUNTIME_TYPE_TO_RUNTIME_CLIENT[T];

  async init(): Promise<void> {
    throw new Error('Not implemented');
  }

  constructor() {
    this.init();
  }

  execute(query: string): Promise<RUNTIME_TYPE_TO_RUNTIME_RESPONSE[T]> {
    throw new Error('Not implemented');
  }

  sanitizeQuery(query: string): string {
    const sanitizedQuery = this.excludeComments(query);

    if (!query || typeof query !== 'string') throw new Error('Query must be typeof STRING');

    const isMutatingQuery = this.isMutatingQuery(sanitizedQuery);
    if (isMutatingQuery) throw new Error('Cannot run any DML | DDL operations except for SELECT');

    const isSelectingQuery = this.isSelectingQuery(sanitizedQuery);
    if (!isSelectingQuery) throw new Error('Only SELECT queries can be processed!');

    const isSelectingQueryFromForbiddenTables = this.isForbiddenTablesSelectingQuery(sanitizedQuery);
    if (isSelectingQueryFromForbiddenTables) throw new Error('Cannot SELECT from internal tables');

    return sanitizedQuery;
  }

  interpolateSystemRelationNamesIntoQuery(query: string, servedTables: string[], postfix: string | number): string {
    throw new Error('Not implemented');
  }

  processErrorMessage(errorMsg?: string, options: ErrorMessageProcessorOptions = {}): string {
    throw new Error('Not implemented');
  }

  processQueryResult(result: RUNTIME_TYPE_TO_RUNTIME_RESPONSE[T]): CommonProcessedRuntimeResponse {
    throw new Error('Not implemented');
  }

  isMutatingQuery(maybeMutatingQuery: string): maybeMutatingQuery is MutatingQuery {
    throw new Error('Not implemented');
  }

  isForbiddenTablesSelectingQuery(
    maybeForbiddenTablesSelectingQuery: string,
  ): maybeForbiddenTablesSelectingQuery is ForbiddenTablesSelectingQuery {
    throw new Error('Not implemented');
  }

  isSelectingQuery(maybeSelectingQuery: string): maybeSelectingQuery is SelectingQuery {
    throw new Error('Not implemented');
  }

  protected processQueryResultFields(queryResult: RUNTIME_TYPE_TO_RUNTIME_RESPONSE[T]): CommonProcessedRuntimeResponse {
    throw new Error('Not implemented');
  }

  protected excludeSystemFields(sourceObject: Record<string, any>): Record<string, any> {
    throw new Error('Not implemented');
  }

  private excludeComments(query: string): string {
    return query.replace(/--.*$/gm, '').trim();
  }
}
