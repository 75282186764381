import { useRouter } from 'next/router';
import { setUTMParamsCookie } from '@/utils';
import { useEffectOnceWhen } from '@/hooks';

type UtmParam = `utm_${string}`;

type UtmParams = {
  [key in UtmParam]: string;
};

const utmParams: UtmParams = {};

const isUtmKey = (key: string): key is UtmParam => {
  const keyPrefix = key.split('_')[0];

  return keyPrefix === 'utm';
};

export const useUTMCapture = () => {
  const { query, push } = useRouter();

  useEffectOnceWhen(() => {
    for (const key of Object.keys(query)) {
      const value = query[key];

      if (typeof value === 'string' && isUtmKey(key) && value) {
        utmParams[key] = value;
      }
    }

    setUTMParamsCookie(utmParams);

    push({ query: undefined }, { query: undefined }, { shallow: true });
  }, Object.keys(query).length && Object.keys(query).some((key) => key.includes('utm_')));
};
