import Stripe from 'stripe';
import { BillingPlanType, STRIPE_SK, STRIPE_WEBHOOK_SUBSCRIPTION_SECRET } from '@/constants';
import { PayAndSubscribeValues } from '@/types/pages/checkout';
import { Logger } from '@/services';

const stripe = new Stripe(STRIPE_SK, {
  apiVersion: '2023-10-16',
});

class StripeService {
  // Creates and returns a Customer object.
  public createCustomer = async (
    userData: PayAndSubscribeValues,
  ): Promise<Stripe.Response<Stripe.Customer | Stripe.DeletedCustomer>> => {
    const { email, holderName, country, postalCode, paymentMethodId } = userData;

    return stripe.customers.create({
      email,
      name: holderName,
      address: { country, postal_code: postalCode },
      payment_method: paymentMethodId,
      invoice_settings: { default_payment_method: paymentMethodId },
    });
  };

  // Creates and returns a subscription object for a customer.
  public createSubscription = async (
    userId: string,
    customerId: string,
    productId: string,
    subscriptionType: BillingPlanType,
  ): Promise<Stripe.Response<Stripe.Subscription> | null> => {
    const { default_price: priceId } = await stripe.products.retrieve(productId);
    const { currency, recurring, unit_amount: unitAmount } = await stripe.prices.retrieve(priceId as string);

    if (!recurring || !unitAmount) {
      return null;
    }

    return stripe.subscriptions.create({
      metadata: { userId, subscriptionType },
      customer: customerId,
      items: [
        {
          price_data: {
            currency,
            product: productId,
            unit_amount: unitAmount,
            recurring: { interval: recurring.interval },
          },
        },
      ],
      payment_settings: {
        payment_method_types: ['card'],
        save_default_payment_method: 'on_subscription',
      },
      expand: ['latest_invoice.payment_intent'],
    });
  };

  // Creates and returns a PaymentIntent object.
  public createPaymentIntent = async (
    userId: string,
    userEmail: string,
    customerId: string,
    productId: string,
    paymentMethodId: string,
    subscriptionType: BillingPlanType,
  ): Promise<Stripe.Response<Stripe.PaymentIntent> | null> => {
    const { default_price: priceId } = await stripe.products.retrieve(productId);
    const { currency, unit_amount: unitAmount } = await stripe.prices.retrieve(priceId as string);

    if (!unitAmount) {
      return null;
    }

    return stripe.paymentIntents.create({
      customer: customerId,
      amount: unitAmount,
      currency,
      payment_method: paymentMethodId,
      payment_method_types: ['card'],
      confirm: true,
      metadata: { userId, subscriptionType, userEmail },
    });
  };

  public getSubscription = async (
    stripeSubscriptionId: string,
  ): Promise<Stripe.Response<Stripe.Subscription> | null> => {
    try {
      return await stripe.subscriptions.retrieve(stripeSubscriptionId);
    } catch (e: any) {
      Logger.error(e);
      return null;
    }
  };

  public getLastActiveSubscription = async ({
    customerId,
  }: {
    customerId: string | null;
  }): Promise<Stripe.Subscription | null> => {
    try {
      if (!customerId) {
        return null;
      }

      const { data } = await stripe.subscriptions.list({ customer: customerId, status: 'active' });

      return data[0] || null;
    } catch (e: any) {
      Logger.error(e);
      return null;
    }
  };

  public getLastLifeTimeSubscription = async ({
    customerId,
  }: {
    customerId: string | null;
  }): Promise<Stripe.PaymentIntent | null> => {
    try {
      if (!customerId) {
        return null;
      }

      const { data } = await stripe.paymentIntents.list({ customer: customerId });

      return data.filter(({ metadata }) => metadata.subscriptionType === BillingPlanType.Lifetime)[0] || null;
    } catch (e: any) {
      Logger.error(e);
      return null;
    }
  };

  public getPaymentIntent = async (id: string): Promise<Stripe.Response<Stripe.PaymentIntent> | null> => {
    try {
      return await stripe.paymentIntents.retrieve(id);
    } catch (e: any) {
      Logger.error(e);
      return null;
    }
  };

  public getCustomer = async (
    customerId: string,
  ): Promise<Stripe.Response<Stripe.Customer | Stripe.DeletedCustomer> | null> => {
    try {
      return await stripe.customers.retrieve(customerId);
    } catch (e: any) {
      Logger.error(e);
      return null;
    }
  };

  public updateCustomerDefaultPaymentMethod = async (
    customerId: string,
    paymentMethodId: string,
  ): Promise<Stripe.Response<Stripe.Customer | Stripe.DeletedCustomer>> => {
    return stripe.customers.update(customerId, {
      invoice_settings: { default_payment_method: paymentMethodId },
    });
  };

  public getPaymentMethod = async (paymentMethodId: string): Promise<Stripe.Response<Stripe.PaymentMethod> | null> => {
    try {
      return await stripe.paymentMethods.retrieve(paymentMethodId);
    } catch (e: any) {
      Logger.error(e);
      return null;
    }
  };

  public attachPaymentMethod = async (
    customerId: string,
    paymentMethodId: string,
  ): Promise<Stripe.Response<Stripe.PaymentMethod>> => {
    return stripe.paymentMethods.attach(paymentMethodId, { customer: customerId });
  };

  public deletePaymentMethod = async (paymentMethodId: string): Promise<Stripe.Response<Stripe.PaymentMethod>> => {
    return stripe.paymentMethods.detach(paymentMethodId);
  };

  public constructEvent = async (
    payload: string | Buffer,
    sig: string | Buffer | Array<string>,
  ): Promise<Stripe.Event | null> => {
    try {
      return await stripe.webhooks.constructEventAsync(payload, sig, STRIPE_WEBHOOK_SUBSCRIPTION_SECRET);
    } catch (e: any) {
      Logger.error(e);
      return null;
    }
  };

  public cancelSubscriptionAtPeriodEnd = async (
    stripeSubscriptionId: string,
  ): Promise<Stripe.Response<Stripe.Subscription> | null> => {
    try {
      return await stripe.subscriptions.update(stripeSubscriptionId, { cancel_at_period_end: true });
    } catch (e: any) {
      Logger.error(e);
      return null;
    }
  };
}

const stripeService = new StripeService();

export default stripeService;
