import { UserService } from '@/services';
import { useEffectOnceWhen, useUser } from '@/hooks';
import { getUTMParams } from '@/utils';
import { User } from '@/types/models';

export const useUTMTrack = () => {
  const { user } = useUser();

  useEffectOnceWhen(() => {
    // condition to avoid TS error
    if (!user) {
      return;
    }

    const utmParams = getUTMParams();

    UserService.updateUsersDataById(user.id, utmParams as Partial<User>);
  }, user && !user.utmSource);
};
