import { AccessGroup } from '@/constants';
import { UTMParams } from '@/types/models';
import { UserService } from '@/services';

export const addAccessGroup = (accessGroups: AccessGroup[] | null, newGroup: AccessGroup): AccessGroup[] => {
  if (!accessGroups) {
    return [newGroup];
  }

  if (accessGroups.includes(newGroup)) {
    return accessGroups;
  }

  return [...accessGroups, newGroup];
};

export const setPremiumAccessGroupToUser = ({
  userId,
  utmParams,
  accessGroups,
}: {
  userId: string;
  utmParams: Partial<UTMParams>;
  accessGroups: AccessGroup[] | null;
}) => {
  const updatedAccessGroup = addAccessGroup(accessGroups, AccessGroup.PremiumQuestions);

  if (accessGroups !== updatedAccessGroup) {
    UserService.updateUsersDataById(userId, { access_groups: updatedAccessGroup as any, ...utmParams }, {}, true);
  }
};
