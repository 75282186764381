import Cookies, { CookieAttributes } from 'js-cookie';
import { UTM_PARAMS_KEY } from '@/constants';
import { NextApiRequest } from 'next';
import { UTMParams } from '@/types/models';
import { convertKeysToCamelCase } from '@/utils/convertKeysToCamelCase';
import { AuthenticatedRequest } from '@/hofs';

const INITIAL_UTM_PARAMS_PREFIX = 'initial_';
const UTM_EXPIRATION_PERIOD = 1000 * 60 * 60 * 24 * 30; // 30 days
const UTM_OPTIONS: CookieAttributes = { expires: UTM_EXPIRATION_PERIOD };

const setLandingPage = () => {
  if (typeof window !== 'undefined') {
    return Cookies.set('landing_page', window.location.href, UTM_OPTIONS);
  }
};
const setReferrerPage = () => {
  if (typeof document !== 'undefined') {
    return Cookies.set('referrer', document.referrer, UTM_OPTIONS);
  }
};

export const setInitialUTMParamsCookie = (utmParams: Record<string, string>) => {
  if (typeof document !== 'undefined') {
    setLandingPage();
    setReferrerPage();

    Object.keys(utmParams).map((key) => Cookies.set(`${INITIAL_UTM_PARAMS_PREFIX}${key}`, utmParams[key], UTM_OPTIONS));
    return Cookies.set(UTM_PARAMS_KEY, JSON.stringify(utmParams));
  }
};

export const setUTMParamsCookie = (utmParams: Record<string, string>) => {
  if (typeof document !== 'undefined') {
    setInitialUTMParamsCookie(utmParams);

    return Cookies.set(UTM_PARAMS_KEY, JSON.stringify(utmParams), UTM_OPTIONS);
  }
};

export const getUTMParamsFromReq = (
  req: NextApiRequest | AuthenticatedRequest,
  shouldReturnInSnakeCase?: boolean,
): Partial<UTMParams> => {
  const utmParams = req.cookies.utm_params || '{}';

  return utmParams
    ? shouldReturnInSnakeCase
      ? JSON.parse(utmParams)
      : convertKeysToCamelCase(JSON.parse(utmParams))
    : {};
};

export const getUTMParams = (): Partial<UTMParams> => {
  if (typeof document !== 'undefined') {
    const utmParams = Cookies.get(UTM_PARAMS_KEY) || '{}';

    return utmParams ? convertKeysToCamelCase(JSON.parse(utmParams)) : {};
  }

  return {};
};
